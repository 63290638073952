'use client'
import React from 'react'
import styled from 'styled-components'

import TitleLinkBlock from '../../../compositions/_subsite/titleLinkBlock'
import ImageBuilder from '../../../elements/imageBuilder'

const Wrapper = styled.div`
  background: url(${(props) => props.background});
  background-position: center center;
  background-size: cover;
  position: relative;
  width: 100%;
  aspect-ratio: 2.89;
  margin-bottom: -18px;

  @media screen and (max-width: 700px) {
    min-height: 340px;
  }

  a {
    display: block;

    &:focus {
      outline: 2px solid #000;
    }
  }

  .contentContainer {
    position: absolute;
    height: 350px;
    max-height: 100%;
    top: calc(46.2% - 161.7px);
    left: 15.4%;

    @media screen and (max-width: 1300px) {
      position: relative;
      top: 0;
      left: 0;

      margin: 60px 0;
    }

    > div {
      padding-bottom: 67px;

      min-width: 500px;

      @media screen and (max-width: 1000px) {
        min-width: calc(100%);
        left: 20px;
        padding: 20px 56px;
      }
    }
  }

  .mediaarea {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    span {
      height: inherit !important;
      img {
        object-fit: cover;
        object-position: center;
      }
    }
  }
`

const FooterImageCta = ({ title, link, image }) => {
  return (
    <Wrapper background={image?.url}>
      {image && (
        <div className="mediaarea">
          <ImageBuilder image={image} layout="fill" />
        </div>
      )}
      <div className="contentContainer">
        <TitleLinkBlock title={title} link={link} />
      </div>
    </Wrapper>
  )
}

export default FooterImageCta
