'use client'
import React from 'react'
import styled from 'styled-components'

import * as importedSections from '../sections'

export const sectionElementDict = {
  add_event: 'AddEvent',
  article: 'Article',
  colored_links: 'ColoredLinksSections',
  home_cta_section: 'CtaSection',
  filter_content: 'FilterContentSection',
  iframe: 'IframeSection',
  embed: 'IframeSection',
  info: 'Information',
  link_list_image: 'LinkListImageSection',
  location_info: 'LocationInfo',
  location_intro: 'LocationIntro',
  locations: 'Locations',
  locations_square: 'LocationSquareSection',
  mediatext: 'MediaTextSection',
  news: 'NewsHomeSection',
  openingschema: 'OpeningSchema',
  overview_intro: 'OverviewIntro',
  product_choice: 'ProductChoiceSection',
  providers: 'Providers',
  search: 'SearchSection',
  success: 'SuccessSection',
  text: 'TextBlock',
  upcoming: 'UpcomingEvents',
  upcoming_small: 'UpcomingEventsSmall',
  vimeo: 'VimeoSection',
  home_cta: 'HomeCtaSection',
  events: 'EventCalendarHome',
  search_cta: 'SkewedCta',
  links: 'LinkListImageSection',

  about: 'AboutSection',
  activities: 'ActivitiesSection',
  facilities: 'AllFacilities',
  subsite_article: 'SubsiteArticle',
  article_intro: 'ArticleIntro',
  richtext: 'ArticleRichText',
  contact: 'ContactSection',
  facilities_small: 'FacilitiesSection',
  // "SubsiteFooterSection",
  // "SubsiteHeaderSection",
  main_info: 'MainInfo',
  openingtimes_info: 'MainInfo',
  pricetables_info: 'MainInfo',
  subsite_news: 'SubsiteNewsSection',
  subsite_news_short: 'SubsiteShortNewsSection',
}

export const subsiteSectionElementDict = {
  more_info: 'AboutSection',
  activities: 'ActivitiesSection',
  section: 'SubsiteArticle',
  facilities: 'FacilitiesSection',
  news: 'SubsiteShortNewsSection',
  events: 'EventCalendarHome',
  contact: 'ContactSection',
  mediatext: 'MediaTextSection',
  embed: 'IframeSection',
}

const getSectionElement = (type, isSubsite) => {
  return isSubsite ? subsiteSectionElementDict[type] : sectionElementDict[type]
}

const Wrapper = styled.div`
  section {
    margin-bottom: 120px;

    @media screen and (max-width: 1000px) {
      margin-bottom: 60px;
    }
  }
`

// This wraps the page builder and the page navigator
const HomepageBuilderWrapper = ({ body, isSubsite }) => {
  return (
    <Wrapper>
      {body &&
        body?.map((sectionData, index) => {
          const sectionType = getSectionElement(sectionData.type, isSubsite)
          const SectionElement = importedSections.default[sectionType]
          if (!SectionElement) {
            return null
          }
          return (
            <SectionElement
              {...sectionData?.value}
              key={`section-element-${index}`}
              sectionData={sectionData.value}
              index={index}
            />
          )
        })}
    </Wrapper>
  )
}
HomepageBuilderWrapper._defaultProps = {}
export default HomepageBuilderWrapper
