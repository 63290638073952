'use client'
import React from 'react'

import HomepageBuilderWrapper from '../../page-builder/homepageBuilder'
import FooterImageCta from '../../modules/_subsite/footerImageCta'

const HomePage = ({ body, footerCta }) => {
  return (
    <>
      <HomepageBuilderWrapper body={body} isSubsite={true} />

      {footerCta && <FooterImageCta {...footerCta} />}
    </>
  )
}

export default HomePage
