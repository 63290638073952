import React from 'react'
import styled from 'styled-components'

import TypographyElement from '../../../elements/typography'
import ButtonElement from '../../../elements/buttons'
import { button_text_black, white } from '../../../colors'

const Wrapper = styled.div`
  display: flex;
  max-width: 422px;
  padding: 44px 56px;
  background-color: ${white};
  text-transform: uppercase;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-height: 350px;

  @media screen and (max-width: 400px) {
    button {
      svg {
      }
    }
  }
`

const TitleLinkBlock = ({ title, link }) => {
  return (
    <Wrapper>
      <TypographyElement
        variant="title"
        component="h2"
        color={button_text_black}
      >
        {title}
      </TypographyElement>
      {link && (
        <ButtonElement variant="primary" href={link.href || link.external_url}>
          {link.text}
        </ButtonElement>
      )}
    </Wrapper>
  )
}

export default TitleLinkBlock
